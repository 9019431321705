import { useMediaQuery } from "@mui/material";
import * as React from "react";
import { useState } from "react";

type Props = {
  children: React.ReactNode;
};

export const ThemeContext = React.createContext({
  isDarkTheme: false,
  toggleTheme: () => {},
});

const ThemeSwitcher: React.FC<Props> = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [isDarkTheme, setIsDarkTheme] = useState(prefersDarkMode);
  const toggleTheme = (): void => {
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeSwitcher };

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"49e95f6c12352fe03e5d419f583a784e"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Roboto","file":"https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap"},{"name":"Roboto Flex","file":"https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap"},{"name":"Noto Sans","file":"https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&display=swap"},{"name":"PT Sans","file":"https://fonts.googleapis.com/css2?family=PT+Sans&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

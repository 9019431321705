import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import "./src/styles/global.css";
import { ThemeSwitcher } from "./src/context/theme.context";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <ThemeSwitcher>{element}</ThemeSwitcher>;
};
